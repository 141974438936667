import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { SharedService } from 'src/app/shared/services/shared.service';
import { UPUtilityService, ProductLaunch, RelinkStatus } from 'up';

@Component({
  selector: 'go-relink-launch',
  templateUrl: './relink-launch.component.html',
  styleUrls: ['./relink-launch.component.scss'],
})
export class RelinkLaunchComponent {
  constructor(
    private sharedService: SharedService,
    private router: Router,
    private upUtilityService: UPUtilityService,
  ) {}
  productLaunch: ProductLaunch = {
    launchAppParam: 'GO',
    routePath: '/products/chapters',
    routePathWarning: '/products/warning',
    productWarningParam: {
      state: {
        platform: 'McGraw Hill GO',
      },
    },
  };

  async relinkStatusEvent(relinkStatus: RelinkStatus) {
    if (relinkStatus === RelinkStatus.DISABLED) {
      this.selectProduct();
    } else if (relinkStatus === RelinkStatus.COMPLETE) {
      try {
        const newTab = await this.upUtilityService.launchAppInNewTab(
          this.productLaunch.launchAppParam,
        );
        if (newTab) {
          newTab.location.href = location.origin + this.productLaunch.routePath;
          this.router.navigate([this.productLaunch.routePathWarning], {
            state: {
              platform: 'McGraw Hill GO',
              isCourseLaunchLevelLaunch:
                this.sharedService.getCourseLaunchLevel(),
            },
          });
        } else {
          this.sharedService.getLtiParams().subscribe(() => {
            this.router.navigateByUrl('/products/launch');
          });
        }
      } catch (error) {
        console.error(
          'There is some problem relinking this assignment:',
          error,
        );
      }
    }
  }

  selectProduct() {
    this.sharedService.setIsCourseCopy(false);
    this.router.navigateByUrl('/products/select');
  }
}
